<template>
  <div>
    <p>101</p>
    <div>
      <router-link to="/exception/second/104"> 我要去104</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
